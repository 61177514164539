// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Field from 'buyFlow/components/Field';

// Styling
import { nameWrapper,
  inputWrapper,
  delete as styledDelete } from './styles.module.css';

const TicketName = ({
  maxExtraNames,
  index,
  remove,
  values: { printPurchaserName, ticketNames },
}) => {
  const hasAtLeastOneName = !printPurchaserName && ticketNames.length <= 1;
  // If the user has already input all additional names and then
  // decided to include the purchaser name, remove the last field
  if (index === maxExtraNames - 1 && printPurchaserName) {
    return '';
  }

  return (
    <div key={`ticketName-${index}`}>
      <div
        name={`ticketNames[${index}]`}
        className={`u-grey-background ${nameWrapper}`}
        style={{ padding: '25px' }}
      >
        <div className={inputWrapper}>
          <Field
            name={`ticketNames[${index}].first_name`}
            label="First Name"
            type="text"
            isRequired
          />
        </div>
        <div className={inputWrapper}>
          <Field
            name={`ticketNames[${index}].last_name`}
            label="Last Name"
            type="text"
            isRequired
          />
        </div>
        <button
          type="button"
          className={`theme-color-primary' ${styledDelete}`}
          disabled={hasAtLeastOneName}
          onClick={() => remove(index)}
        >
          DELETE
        </button>
      </div>
    </div>
  );
};

TicketName.propTypes = {
  remove: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  maxExtraNames: PropTypes.number.isRequired,
  // Permit objects proptype for dynamic object (form values)
  // eslint-disable-next-line
  values: PropTypes.object,
};

export default TicketName;
