// Libraries
import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';

// Styling
import iconNotice from 'images/icon-notice.svg';
import styles from './styles.module.css';

const BuyFlowAlert = ({ isOpen, onClose, text }) => (
  <ReactModal
    isOpen={isOpen}
    contentLabel="Order Tickets Modal"
    className={styles.modal}
    overlayClassName={styles.overlay}
  >
    <div className={styles.container}>
      <img className={styles.icon} src={iconNotice} alt="Notice Icon" />
      <p className={`h5 theme-color-primary ${styles.heading}`}>{text}</p>
      <button
        type="button"
        onClick={onClose}
        className={`button button--buyflow theme-background-primary ${
          styles.button
        }`}
      >
        Close
      </button>
    </div>
  </ReactModal>
);

BuyFlowAlert.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default BuyFlowAlert;
