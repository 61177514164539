// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Utilities
import { ticketNamesEntry } from 'utils/attributes';

// Components
import TicketName from 'buyFlow/components/TicketName';

// Styling
import { fieldDetails } from './styles.module.css';

// Constants
const MAX_EXTRA_NAMES = 3;

const generateTicketNames = (values, remove) =>
  values.ticketNames.map(({ key }, index) => (
    <TicketName
      key={key}
      maxExtraNames={MAX_EXTRA_NAMES}
      index={index}
      remove={remove}
      values={values}
    />
  ));

const TicketNamesList = ({ values, ticketNames, printPurchaserName, remove, push }) => {
  const trimmedTicketNames =
    printPurchaserName && ticketNames.length === MAX_EXTRA_NAMES ? ticketNames.pop() : ticketNames;

  return (
    <div>
      {generateTicketNames(values, remove)}
      <button
        type="button"
        // Allow 1 fewer additional names if the purchasers name is included
        disabled={
          ticketNames.length >= (printPurchaserName ? MAX_EXTRA_NAMES - 1 : MAX_EXTRA_NAMES)
        }
        className="button button--buyflow theme-background-primary u-text-align-center"
        style={{ marginTop: '25px' }}
        onClick={() => push(ticketNamesEntry(`ticket${trimmedTicketNames.length + 1}`))}
      >
        Add another person
      </button>
      <p className={fieldDetails} style={{ fontWeight: 'bold' }}>
        Maximum of 3 names can be be printed on a ticket.
        {printPurchaserName && ' The purchaser name will automatically be included.'}
      </p>
      <p className={fieldDetails}>
        Please note: All tickets issued will contain the same information. Tickets cannot be
        individualized. All ticket packs will contain the same information.
      </p>
    </div>
  );
};

TicketNamesList.propTypes = {
  ticketNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  printPurchaserName: PropTypes.bool.isRequired,
  remove: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  // Permit objects proptype for dynamic object (form values)
  // eslint-disable-next-line
  values: PropTypes.object.isRequired
};

export default TicketNamesList;
