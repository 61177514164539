// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { Form, FieldArray } from 'formik';

// Utilities
import { ticketNamesEntry } from 'utils/attributes';
import { camelCaseToSentence } from 'utils/helpers';

// Components
import PhoneField from 'buyFlow/components/PhoneField';
import RadioChoice from 'buyFlow/components/RadioChoice';
import MarketingAttributeList from 'buyFlow/components/MarketingAttributeList';
import FieldGroup from 'buyFlow/components/FieldGroup';
import EmailSignup from 'buyFlow/components/EmailSignup';
import TicketNamesList from 'buyFlow/components/TicketNamesList';

// Styling
import { fieldGroup } from './styles.module.css';

const generateFieldData = ({
  name,
  type = 'text',
  label,
  isRequired = false,
  placeholder,
  disabled = false,
  uppercase
}) => ({
  name,
  type,
  label: label || camelCaseToSentence(name),
  isRequired,
  placeholder: placeholder || '',
  disabled,
  uppercase
});

// TODO: add emailSignup tp data?
// TODO: split this into several components
const UserForm = ({ values, attributes, setFieldValue, emailSignup }) => {
  const { ticketNames, printPurchaserName, groupPurchase } = values;

  return (
    <Form>
      <FieldGroup
        fields={[
          generateFieldData({ name: 'firstName', isRequired: true }),
          generateFieldData({ name: 'lastName', isRequired: true })
        ]}
        details="Purchasers must be 18 years of age or older"
      />
      <FieldGroup
        fields={[
          generateFieldData({ name: 'address', isRequired: true }),
          generateFieldData({ name: 'city', isRequired: true }),
          // TODO: make this data driven post-Kinsmen
          generateFieldData({
            name: 'province',
            isRequired: true,
            disabled: true
          }),
          generateFieldData({
            name: 'postalCode',
            isRequired: true,
            uppercase: true
          })
        ]}
      />
      <FieldGroup
        fields={[
          generateFieldData({ name: 'email', isRequired: true }),
          generateFieldData({
            name: 'confirmEmail',
            isRequired: true,
            description: 'Your tickets will be sent to the email address provided'
          })
        ]}
      >
        <PhoneField
          {...generateFieldData({
            type: 'tel',
            name: 'homePhone',
            isRequired: true
          })}
        />
        <PhoneField
          {...generateFieldData({
            type: 'tel',
            name: 'mobilePhone',
            isRequired: false,
          })}
        />
      </FieldGroup>
      <div className={fieldGroup} id="groupPurchase">
        <div className="h5 theme-color-primary">Group Purchase</div>
        <p>Are these tickets purchased as a gift or as a group purchase?</p>
        <RadioChoice name="groupPurchase" />
      </div>
      <div className={fieldGroup} id="printPurchaserName" hidden={!groupPurchase}>
        <p>Should the purchasers name be printed on the tickets as well?</p>
        <RadioChoice
          name="printPurchaserName"
          onNo={() => {
            if (ticketNames.length === 0) {
              setFieldValue('ticketNames', [ticketNamesEntry()]);
            }
          }}
        />
      </div>
      <div className={fieldGroup} id="ticketName" hidden={!groupPurchase}>
        <p>
          Enter the
          {printPurchaserName ? ' additional ' : ' '}
          names you wish to appear on the tickets.
        </p>
        <FieldArray
          name="ticketNames"
          render={({remove, push}) => (
            <TicketNamesList
              push={push}
              remove={remove}
              values={values}
              ticketNames={ticketNames}
              printPurchaserName={printPurchaserName}
            />
          )}
        />
      </div>
      <div className={fieldGroup} id="attributes">
        <MarketingAttributeList attributes={attributes} />
      </div>
      {emailSignup && <EmailSignup />}
      <div className="u-text-align-center">
        <button type="submit" className="button button--buyflow theme-background-primary">
          Submit
        </button>
      </div>
    </Form>
  );
};

UserForm.propTypes = {
  errors: PropTypes.shape({
    title: PropTypes.string,
    message: PropTypes.string
  }),
  setFieldValue: PropTypes.func.isRequired,
  emailSignup: PropTypes.bool,
  // Permit objects for dynamic 'marketing attributes' object from Elmar
  // eslint-disable-next-line
  attributes: PropTypes.array,
  // Permit objects proptype for dynamic object (form values)
  // eslint-disable-next-line
  values: PropTypes.object.isRequired,
};

UserForm.defaultProps = {
  errors: {},
  attributes: [],
  emailSignup: false
};

export default UserForm;
