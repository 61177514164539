// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

// Components
import Icon from 'common/components/Icon';

// Styling
import { banner,
  bannerContent,
  backButton,
  backText,
  logo,
  logoImage,
  title } from './styles.module.css';

const LotteryBanner = ({ name, onBack, disableBackButton, programLogo }) => (
  <div className={`theme-background-primary ${banner}`}>
    <div className={bannerContent}>
      <button
        type="button"
        className={backButton}
        onClick={onBack}
        disabled={disableBackButton}
      >
        <Icon icon="undo" />
        <span className={backText}>BACK</span>
      </button>
    </div>
    <div className={bannerContent}>
      <Link to="/" className={logo}>
        <img
          className={logoImage}
          src={programLogo}
          alt=" "
        />
      </Link>
      <div className={title}>
        <p>{name}</p>
      </div>
    </div>
  </div>
);

LotteryBanner.propTypes = {
  name: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
  disableBackButton: PropTypes.bool.isRequired,
  programLogo: PropTypes.string,
};

LotteryBanner.defaultProps = {
  programLogo: 'http://via.placeholder.com/142x103',
};

export default LotteryBanner;
