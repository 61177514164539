// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Icon from 'common/components/Icon';

// Styling
import { container, icon, text } from './styles.module.css';

const WaitlistAlert = ({ onAccept, onBack }) => (
  <div className={`buyflow-form ${container}`}>
    <Icon icon="error_outline" color="#4a90e2" className={icon} />
    <p className={`h4 theme-color-primary ${text}`}>
      Your order contains waitlisted items
    </p>
    <p className="buyflow-form__text u-text-align-center">
      Waitlisted items will be processed in the order received.
    </p>
    <p className="buyflow-form__text u-text-align-center">
      Your card will not be charged unless tickets are available at the time of
      your order.
    </p>
    <button
      type="button"
      className="button button--buyflow theme-background-primary"
      onClick={onAccept}
    >
      I Understand
    </button>
    <div className="buyflow-form__button-wrapper">
      <button type="button" className="button button--link" onClick={onBack}>
        Go Back
      </button>
    </div>
  </div>
);

WaitlistAlert.propTypes = {
  onAccept: PropTypes.func,
  onBack: PropTypes.func,
};

export default WaitlistAlert;
