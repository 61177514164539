// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { connect, Field } from 'formik';

// Styling
import { radioButton, radioButtonWrapper } from './styles.module.css';

const RadioChoice = ({ name, formik, onYes, onNo }) => (
  <div className={radioButtonWrapper}>
    <div className={radioButton}>
      <Field
        id={`${name}-yes`}
        aria-labelledby={`${name}-yes`}
        type="radio"
        name={name}
        checked={formik.values[name]}
        label="yes"
        onChange={() => {
          if (onYes) onYes();
          formik.setFieldValue(name, true);
        }}
      />
      <label htmlFor={`${name}-yes`} id={`${name}-label-yes`}>
        Yes
      </label>
    </div>
    <div className={radioButton}>
      <Field
        id={`${name}-no`}
        aria-labelledby={`${name}-no`}
        type="radio"
        name={name}
        checked={!formik.values[name]}
        label="no"
        onChange={() => {
          if (onNo) onNo();
          formik.setFieldValue(name, false);
        }}
      />
      <label htmlFor={`${name}-no`} id={`${name}-label-no`}>
        No
      </label>
    </div>
  </div>
);

RadioChoice.propTypes = {
  name: PropTypes.string,
  onNo: PropTypes.func,
  onYes: PropTypes.func,
  // eslint-disable-next-line
  formik: PropTypes.object,
};

RadioChoice.defaultProps = {
  name: '',
  onNo: () => {},
  onYes: () => {},
}

export default connect(RadioChoice);
