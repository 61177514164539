// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

// Components
import Button from 'common/components/Button';
import BestDeal from 'buyFlow/components/BestDeal';

// Styling
import { ticketGrid, buttonCenter } from './styles.module.css';

const TicketOrder = ({ generatedTickets, bestDeal, onSetOrder, handleNextButton, drawCode }) => (
  <>
    <div className={ticketGrid}>{generatedTickets}</div>
    <BestDeal
      text={bestDeal.get('dealText')}
      bonusTickets={bestDeal.get('bonusTickets')}
      priceSavings={bestDeal.get('priceSavings')}
      onSetOrder={() => onSetOrder(bestDeal.get('optimizedOrder'), drawCode)}
    />
    <div className={buttonCenter}>
      <Button
        text="Next"
        modifiers="buyflow"
        extraClasses="theme-background-primary"
        onClick={() => {
          handleNextButton(drawCode);
        }}
      />
    </div>
  </>
);

TicketOrder.propTypes = {
  generatedTickets: ImmutablePropTypes.list.isRequired,
  bestDeal: ImmutablePropTypes.map.isRequired,
  onSetOrder: PropTypes.func.isRequired,
  handleNextButton: PropTypes.func.isRequired,
  drawCode: PropTypes.number.isRequired
};

export default TicketOrder;
