// Libraries
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';

class PrivateRoute extends PureComponent {
  render() {
    const { component, jwt } = this.props;
    if (!jwt) {
      navigate('/buy-flow/');
      return null;
    }

    return component();
  }
}

PrivateRoute.propTypes = {
  component: PropTypes.node.isRequired,
  jwt: PropTypes.string.isRequired
};

export default PrivateRoute;
