// Libraries
import React from 'react';
import ImmutableProptypes from 'react-immutable-proptypes';
import classNames from 'classnames/bind';

// Utilities
import { generateBundleDescription,
  generateMaxPackDescription } from 'utils/helpers';
import { isMaxPack } from 'utils/tickets';

// Styling
import styles from './styles.module.css';

const cx = classNames.bind(styles);

const BundleOrderCard = ({ pack }) => {
  const tickets = pack.get('packDrawTypes');
  const orderedPacks = pack.get('ordered');
  const contents = isMaxPack(pack)
    ? generateMaxPackDescription(orderedPacks, tickets)
    : generateBundleDescription(tickets);

  return (
    <div className={styles.orderCard}>
      <div className={cx(styles.cardTitle, 'theme-color-primary')}>
        {pack.get('desc')}
      </div>
      <div className={cx(styles.drawOrderBreakdown, 'theme-color-primary')}>
        <strong>
          {`${orderedPacks} Pack${orderedPacks === 1 ? '' : 's'} `}
        </strong>
        <span>{`(${contents})`}</span>
      </div>
    </div>
  );
};

export default BundleOrderCard;

BundleOrderCard.propTypes = {
  pack: ImmutableProptypes.map.isRequired,
};
