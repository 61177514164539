import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Icon from 'common/components/Icon';

import styles from './styles.module.css';

const cx = classNames.bind(styles);

const DropdownArrow = ({ isOpen, onClick }) => (
  <button
    type="button"
    onClick={onClick}
    className={cx(styles.button, { isOpen })}
  >
    <Icon icon="arrow_drop_down" />
  </button>
);

export default DropdownArrow;

DropdownArrow.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
};
