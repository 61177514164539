// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

// Components
import Head from 'marketing/components/Head';
import ErrorBoundary from 'buyFlow/components/ErrorBoundary';

const Layout = ({ wrapperStyle, children }) => (
  <div className={wrapperStyle}>
    <Head />
    <Helmet>
      <script>dataLayer = []</script>
    </Helmet>
    <ErrorBoundary>{children}</ErrorBoundary>
  </div>
);

Layout.propTypes = {
  wrapperStyle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default Layout;
