// This component needs to be refactored, but cannot be done until state management is built
// Libraries
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';

// Utilities
import { getTotalTickets, getTotalTicketsByDrawId } from 'utils/tickets';

import { scrollToTop } from 'utils/helpers';

// Components
import ErrorContainer from 'buyFlow/containers/ErrorContainer';
import CurrentOrder from 'buyFlow/components/CurrentOrder';
import LotteryBanner from 'buyFlow/components/LotteryBanner';
import BottomNav from 'buyFlow/components/BottomNav';
import FooterText from 'common/components/FooterText';
import AlertContainer from 'buyFlow/containers/AlertContainer';

// State
import * as orderingActions from 'ducks/ordering/actions';
import * as loadingActions from 'ducks/loading/actions';
import * as stageActions from 'ducks/stage/actions';
import * as modalActions from 'ducks/modals/actions';
import * as errorActions from 'ducks/error/actions';
import * as credentialActions from 'ducks/credentials/actions';

// TODO during style refactor -> remove styling from this container
// Styling
import styles from './styles.module.css';

class BuyFlowContainer extends PureComponent {
  onNext = () => {
    scrollToTop();
    const {
      order,
      displayCannotProgress,
      draws,
      stage,
      setStage,
      toggleEmptyCartModal,
      location
    } = this.props;

    const onFinalPurchaseStage = location.pathname === `/buy-flow/tickets-${draws.size - 1}`;
    // TODO: have pack requirements set in data, currently setting the first draw as 'required'
    const firstDrawId = draws.getIn([0, 'code']);

    if (getTotalTickets(order) === 0) {
      toggleEmptyCartModal();
    } else if (onFinalPurchaseStage && getTotalTicketsByDrawId(order, firstDrawId) === 0) {
      displayCannotProgress(true);
    } else if (getTotalTickets(order) > 0 && getTotalTicketsByDrawId(order, firstDrawId) > 0) {
      navigate(`/buy-flow/purchase-order`);
      setStage(stage + 1);
      scrollToTop();
    }
  };

  onBack = () => {
    window.history.back();
    scrollToTop();
  };

  disableBackButton = () => {
    const { location } = this.props;
    const isFirstPage = location.pathname === '/buy-flow/' || location.pathname === '/buy-flow';
    return isFirstPage;
  };

  render() {
    const {
      programLogo,
      order,
      footerLinks,
      displayOrder,
      draws,
      displayContactModal,
      toggleContactModal,
      toggleDisplayOrder,
      title,
      location,
      render
    } = this.props;

    const requiredDrawName = draws.getIn([0, 'desc']);

    return (
      <div className={styles.container}>
        <LotteryBanner
          name={title}
          programLogo={programLogo}
          disableBackButton={this.disableBackButton()}
          onBack={this.onBack}
        />
        {order && (
          <CurrentOrder
            order={order}
            draws={draws}
            displayOrder={displayOrder}
            onToggleShowOrder={toggleDisplayOrder}
            showSummary={location.pathname === 'buy-flow/payment-complete'}
          />
        )}
        <ErrorContainer {...this.props}>
          <div className={styles.innerContainer}>
            {render({
              ...this.props,
              onNext: this.onNext
            })}
          </div>
        </ErrorContainer>
        <BottomNav
          {...footerLinks}
          toggleContactModalState={toggleContactModal}
          displayContactModal={displayContactModal}
        />
        <FooterText />

        <AlertContainer requiredDrawName={requiredDrawName} {...this.props} />
      </div>
    );
  }
}

BuyFlowContainer.propTypes = {
  draws: PropTypes.object,
  order: PropTypes.object,
  statusEndpoint: PropTypes.array.isRequired,
  programLogo: PropTypes.string,
  hasMaxPack: PropTypes.bool,
  footerLinks: PropTypes.object
};

const mapStateToProps = ({
  loadingState,
  modalState,
  orderingState,
  stageState,
  errorState,
  credentialsState,
  menuState
}) => ({
  ...loadingState,
  ...modalState,
  ...orderingState,
  ...stageState,
  ...errorState,
  ...credentialsState,
  ...menuState
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...orderingActions,
      ...modalActions,
      ...errorActions,
      ...loadingActions,
      ...stageActions,
      ...credentialActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuyFlowContainer);
