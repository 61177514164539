// Libraries
import React from 'react';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';

// Utilities
import { errors } from 'utils/api';

// Styling
import iconWarning from 'images/icon-warning.svg';
import styles from './styles.module.css';

const ErrorPage = ({ title, message, reinitializeBuyFlow }) => (
  <div className={`buyflow-form ${styles.container}`}>
    <img className="buyflow-form__icon u-text-align-center" src={iconWarning} alt="Warning Icon" />
    <p className={`h4 theme-color-primary ${styles.text}`}>{title}</p>
    {message.map(text => (
      <p className={`buyflow-form__text ${styles.errorText}`}>{text}</p>
    ))}
    <div>
      <Link to="/buy-flow" className="button button--link" onClick={reinitializeBuyFlow}>
        Cancel Order
      </Link>
    </div>
  </div>
);

ErrorPage.propTypes = {
  title: PropTypes.string,
  message: PropTypes.arrayOf(PropTypes.String),
  reinitializeBuyFlow: PropTypes.func.isRequired
};

ErrorPage.defaultProps = {
  title: errors.STATUS.title,
  message: [errors.STATUS.message]
};

export default ErrorPage;
