// Libraries
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';

// Components
import PurchaserForm from 'buyFlow/components/PurchaserForm';

// State
import { scrollToTop } from 'utils/helpers';

class PurchaseFormContainer extends PureComponent {
  componentDidMount = () => {
    this.props.setTitle('Purchaser Information');
    scrollToTop();
  };

  onSubmitPurchaserForm = values => {
    const { setPurchaserInfo } = this.props;
    navigate(`/buy-flow/confirm-order`);
    scrollToTop();
    setPurchaserInfo(values);
  };

  render() {
    const { attributes, setPurchaserInfo, purchaserInfo } = this.props;

    return (
      <PurchaserForm
        onSubmit={this.onSubmitPurchaserForm}
        onBlur={() => values => setPurchaserInfo(values)}
        attributes={attributes}
        initialValues={purchaserInfo}
      />
    );
  }
}

PurchaseFormContainer.propTypes = {
  attributes: PropTypes.shape({}).isRequired,
  purchaserInfo: PropTypes.shape({}).isRequired,
  setPurchaserInfo: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired
};

export default PurchaseFormContainer;
