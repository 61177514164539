// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames/bind';

// Utilities
import { getOrder, getBundleOrders } from 'utils/tickets';

// Components
import CurrentOrderDropdown from 'buyFlow/components/CurrentOrderDropdown';
import CurrentOrderList from 'buyFlow/components/CurrentOrderList';

// Styling
import styles from './styles.module.css';

const cx = classNames.bind(styles);

const CurrentOrder = ({ order, draws, onToggleShowOrder, displayOrder, showSummary }) => {
  const drawTotals = getOrder(order, draws);
  const bundleTotals = getBundleOrders(order, draws);

  const totalDrawsAndBundles = drawTotals.size + bundleTotals.size;

  const dropdownProps = { order, displayOrder, onToggleShowOrder, totalDrawsAndBundles };
  const orderListProps = { drawTotals, bundleTotals, order };

  return (
    <div className={styles.container}>
      <div className={cx(styles.innerContainer, { openContainer: displayOrder })}>
        {!showSummary && <CurrentOrderDropdown {...dropdownProps} />}
        <div>{(displayOrder || showSummary) && <CurrentOrderList {...orderListProps} />}</div>
      </div>
    </div>
  );
};

export default CurrentOrder;

CurrentOrder.propTypes = {
  order: ImmutablePropTypes.list,
  draws: ImmutablePropTypes.list,
  displayOrder: PropTypes.bool.isRequired,
  onToggleShowOrder: PropTypes.func.isRequired,
  showSummary: PropTypes.bool.isRequired
};

CurrentOrder.defaultProps = {
  order: null,
  draws: null
};
