import React from 'react';
import BuyFlowContainer from 'buyFlow/containers/BuyFlowContainer';
import { Router } from '@reach/router';

// Components
import TicketOrderContainer from 'buyFlow/containers/TicketOrderContainer';
import PurchaseFormContainer from 'buyFlow/containers/PurchaseFormContainer';
import OrderSummaryContainer from 'buyFlow/containers/OrderSummaryContainer';
import PaymentComplete from 'buyFlow/components/PaymentComplete';
import PrivateRoute from 'buyFlow/Routes/PrivateRoute';
import Layout from 'buyFlow/components/Layout';

// Styles
import { wrapper } from 'buyFlow/containers/BuyFlowContainer/styles.module.css';

import data from 'data/data.json';

const BuyFlow = ({ location }) => {
  const {
    buyFlowData: { epStatus, gateways, hasMaxPack },
    appearance: { programLogo } = {},
    rulesPdf,
    privacyPolicyPdf,
    contactUsPage: { details } = {}
  } = data.raffle;

  const footerLinks = { rulesPdf, privacyPolicyPdf, details };

  const BuyFlowRoute = () => (
    <BuyFlowContainer
      gateways={gateways}
      statusEndpoint={epStatus}
      programLogo={programLogo}
      footerLinks={footerLinks}
      hasMaxPack={hasMaxPack}
      location={location}
      render={props => (
        <Router>
          <TicketOrderContainer path="/*" {...props} />
          <PrivateRoute
            {...props}
            path="/purchase-order"
            component={() => <PurchaseFormContainer {...props} />}
          />
          <PrivateRoute
            {...props}
            path="/confirm-order"
            component={() => <OrderSummaryContainer {...props} />}
          />
          <PrivateRoute
            {...props}
            path="/payment-complete"
            component={() => <PaymentComplete {...props} />}
          />
        </Router>
      )}
    />
  );

  return (
    <Layout wrapperStyle={wrapper}>
      <Router>
        <BuyFlowRoute path="buy-flow/*" />
      </Router>
    </Layout>
  );
};

export default BuyFlow;
