// Libraries
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BuyFlowAlert from 'buyFlow/components/BuyFlowAlert';

class AlertContainer extends PureComponent {
  onCloseModals = () => {
    const {
      displayCannotProgress,
      displayEmptyCartModal,
      toggleEmptyCartModal,
      displayRequireTicketModal,
      toggleRequireTicketModal
    } = this.props;
    displayCannotProgress(false);
    if (displayEmptyCartModal) {
      toggleEmptyCartModal();
    }
    if (displayRequireTicketModal) {
      toggleRequireTicketModal();
    }
  };

  render() {
    const {
      displayEmptyCartModal,
      requiredDrawName,
      cannotProgressDisplayed,
      displayRequireTicketModal
    } = this.props;

    return (
      <React.Fragment>
        <BuyFlowAlert
          isOpen={displayEmptyCartModal}
          onClose={this.onCloseModals}
          text="You must add an item to your cart to proceed."
        />
        <BuyFlowAlert
          isOpen={displayRequireTicketModal}
          onClose={this.onCloseModals}
          text={`You must purchase at least one ${requiredDrawName} ticket to proceed.`}
        />
        <BuyFlowAlert
          isOpen={cannotProgressDisplayed}
          onClose={this.onCloseModals}
          text={`You must purchase at least one ${requiredDrawName} ticket to proceed.`}
        />
      </React.Fragment>
    );
  }
}

AlertContainer.propTypes = {
  displayCannotProgress: PropTypes.func.isRequired,
  displayEmptyCartModal: PropTypes.func.isRequired,
  toggleEmptyCartModal: PropTypes.func.isRequired,
  displayRequireTicketModal: PropTypes.func.isRequired,
  toggleRequireTicketModal: PropTypes.func.isRequired,
  cannotProgressDisplayed: PropTypes.bool.isRequired,
  requiredDrawName: PropTypes.string.isRequired
};

export default AlertContainer;
