// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Data
import data from 'data/data.json';

// Styling
import { button } from './styles.module.css';

const TicketButton = ({ icon = 'remove', onClick, disabled }) => {
  const { primaryColour } = data.raffle.appearance;
  return (
    <button
      type="button"
      className={`material-icons icon ${button}`}
      icon={icon}
      onClick={onClick}
      disabled={disabled}
      style={{ backgroundColor: primaryColour }}
    >
      {icon}
    </button>
  );
};

TicketButton.propTypes = {
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

TicketButton.defaultProps = {
  disabled: false,
}

export default TicketButton;
