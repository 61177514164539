// Libraries
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { navigate } from '@reach/router';

// Utilities
import { createSoalaPayment, createConfirmOrder, hasOrderedWaitlistedPacks } from 'utils/tickets';
import { post, errors, extractAuthnetKeys } from 'utils/api';

// Components
import OrderSummary from 'buyFlow/components/OrderSummary';

class OrderSummaryContainer extends PureComponent {
  componentDidMount = () => {
    this.props.setTitle('Your Order Summary');
  };

  sendPaymentToSoala = values => {
    const { nonce, descriptor, verifiedOrder, maskedPan, expMm, expYy } = values;
    const { order, toggleLoading, setOrderSuccess, purchaserInfo, setError, jwt } = this.props;

    const soalaOrder = createSoalaPayment({
      order,
      purchaser: purchaserInfo,
      jwt,
      nonce,
      descriptor,
      verifiedOrder,
      maskedPan,
      expMm,
      expYy
    });

    toggleLoading();

    // TODO: Break this function up, fix this logic. Please.
    const SUCCESS = 200;
    const OK = 'O';
    const allPaymentsSuccess = payments => payments.every(payment => payment.result === OK);
    // TODO: remove payment url override
    post(verifiedOrder.get('epPayment'), soalaOrder).then(res => {
      if (res.status === SUCCESS && allPaymentsSuccess(res.data.payments)) {
        navigate('/buy-flow/payment-complete');
        setOrderSuccess(true);
      } else {
        setError(errors.PAYMENT);
      }
      toggleLoading();
    });
    return false;
  };

  confirmOrder = () => {
    const {
      order,
      setError,
      orderEndpoint,
      purchaserInfo,
      jwt,
      transactionId,
      attributes,
      gateways,
      setPaymentEndpoint,
      setVerifiedOrder,
      setTransactionId
    } = this.props;

    const confirmOrder = createConfirmOrder({
      purchaserInfo,
      jwt,
      order,
      gateways,
      transactionId,
      attributes
    });
    const EXPIRED_SESSION_CODE = 1005;
    const CONFIRM_CODE = 201;

    // TODO: handle async info centrally
    post(orderEndpoint, confirmOrder).then(res => {
      const { data, status } = res;
      if (status === CONFIRM_CODE) {
        const immutableData = fromJS(data);
        setPaymentEndpoint(immutableData.get('epPayment'));
        setTransactionId(immutableData.get('trxId'));
        setVerifiedOrder(extractAuthnetKeys(immutableData));
      } else if (data.errors && data.errors.some(({ code }) => code === EXPIRED_SESSION_CODE)) {
        setError(errors.SESSION);
      } else {
        setError(errors.VERIFY);
      }
    });
  };

  render() {
    const {
      order,
      purchaserInfo,
      verifiedOrder,
      isLoading,
      orderEndpoint,
      waitlistEnabled
    } = this.props;

    return (
      <OrderSummary
        onSubmit={this.sendPaymentToSoala}
        purchaserInfo={purchaserInfo}
        orderEndpoint={orderEndpoint}
        confirmOrder={this.confirmOrder}
        verifiedOrder={verifiedOrder}
        isLoading={isLoading}
        isWaitlisted={hasOrderedWaitlistedPacks(order) && waitlistEnabled}
      />
    );
  }
}

OrderSummaryContainer.propTypes = {
  orderEndpoint: PropTypes.string.isRequired,
  jwt: PropTypes.string.isRequired,
  transactionId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setError: PropTypes.func.isRequired,
  setPaymentEndpoint: PropTypes.func.isRequired,
  setVerifiedOrder: PropTypes.func.isRequired,
  setTransactionId: PropTypes.func.isRequired,
  toggleLoading: PropTypes.func.isRequired,
  setOrderSuccess: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
  waitlistEnabled: PropTypes.bool.isRequired
};

export default OrderSummaryContainer;
