// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames/bind';

// Utilities
import { getTotalPrice } from 'utils/tickets';

// Components
import DropdownArrow from 'common/components/DropdownArrow';

// Styling
import styles from './styles.module.css';

const cx = classNames.bind(styles);

const CurrentOrderDropdown = ({ order, displayOrder, onToggleShowOrder, totalDrawsAndBundles }) => (
  <div className={cx(styles.currentOrder, 'theme-color-primary')}>
    <div className={styles.order}>
      <div className={cx('theme-color-primary')}>Current Order</div>
      {totalDrawsAndBundles > 0 && (
        <DropdownArrow isOpen={!displayOrder} onClick={onToggleShowOrder} />
      )}
    </div>

    <div className={cx('theme-color-primary', styles.total)} id={styles.totalTop}>
      <span className={styles.totalText}>Total</span>
      {` $${getTotalPrice(order) || 0}`}
    </div>
  </div>
);

export default CurrentOrderDropdown;

CurrentOrderDropdown.propTypes = {
  order: ImmutablePropTypes.list.isRequired,
  displayOrder: PropTypes.bool,
  onToggleShowOrder: PropTypes.func.isRequired,
  totalDrawsAndBundles: PropTypes.number
};

CurrentOrderDropdown.defaultProps = {
  totalDrawsAndBundles: 0,
  displayOrder: true
};
