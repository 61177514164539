// Libraries
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { Router, navigate } from '@reach/router';

// Utilities
import { get, errors } from 'utils/api';
import {getPacksByDrawId,
  optimizeOrderForPacks,
  getTotalPrice,
  getTotalTickets,
  getMonoPacks,
  displayDrawQuantities,
  setOrderContentsForDraw,
  extractContentsText,
  orderUpgradeMap,
  isMaxPack,
  isMonoPack,
  getQuantity,
  getMonoPacksByDrawId,
  getTotalTicketsByDrawId,
  generateMaxPack} from 'utils/tickets';

import { denormalize, scrollToTop } from 'utils/helpers';

// Components
import Loading from 'buyFlow/components/Loading';
import TicketQuantity from 'buyFlow/components/TicketQuantity';
import TicketOrder from 'buyFlow/components/TicketOrder';

const DRAWPATHLENGTH = 18;

const generateTicketQuantity = (updateQuantity, tickets) =>
  tickets.map(ticket => (
    <TicketQuantity
      key={ticket.get('code')}
      ticket={ticket}
      updateQuantity={updateQuantity}
      contents={extractContentsText(ticket)}
    />
  ));

const getTitleFromDraws = (path, draws) => {
  if (path === '/buy-flow' || path === '/buy-flow/') {
    return draws.getIn([0, 'desc']);
  }
  return draws.getIn([path[DRAWPATHLENGTH], 'desc']);
};

class TicketOrderContainer extends PureComponent {
  // move some of this out of componentDidMount
  componentDidMount() {
    const { statusEndpoint, toggleLoading, jwt, setError, draws, location } = this.props;
    this.props.setTitle(getTitleFromDraws(location.pathname, draws));

    const getSequential = urls => {
      const url = urls.shift();
      // TODO: move loading logic
      return get(url)
        .then(res => {
          if (res.status === 200) {
            toggleLoading();
            this.updateOrderStatusFromAPI(fromJS(res.data));
          } else if (urls.length > 0) {
            getSequential(urls);
          } else {
            setError(errors.STATUS);
          }
        })
        .catch(() => setError(errors.STATUS));
    };

    const urls = new Array(...statusEndpoint);
    // TODO: only load if status is not gotten
    if (!jwt) {
      toggleLoading();
      getSequential(urls);
    }
  }

  extractBestDealInfo = tickets => {
    const monoPacks = getMonoPacks(tickets);
    const totalPrice = getTotalPrice(monoPacks);
    const totalTickets = getTotalTickets(monoPacks);
    const optimizedOrder = optimizeOrderForPacks(monoPacks, totalPrice);
    const optimizedTotalPrice = getTotalPrice(optimizedOrder);
    const optimizedTotalTickets = getTotalTickets(optimizedOrder);
    const bonusTickets = optimizedTotalTickets - totalTickets;
    const priceSavings = totalPrice - optimizedTotalPrice;

    return fromJS({
      optimizedOrder,
      bonusTickets,
      priceSavings,
      dealText: `Order ${displayDrawQuantities(optimizedOrder)} 
        instead of ${displayDrawQuantities(monoPacks)}.`
    });
  };

  onNextDraw = drawCode => {
    scrollToTop();
    navigate(`/buy-flow/tickets-${drawCode + 1}`);
  };

  handleNextButton = drawCode => {
    const { draws, onNext, location } = this.props;
    return location.pathname !== `/buy-flow/tickets-${draws.size - 1}`
      ? this.onNextDraw(drawCode)
      : onNext();
  };

  updateOrderStatusFromAPI = status => {
    const {
      order,
      draws,
      hasMaxPack,
      setOrderEndpoint,
      setOrder,
      setJwt,
      setUpgradeMap
    } = this.props;

    if (draws) {
      // TODO: fix inconsistent naming of packTypes/packs on back end
      // add a field tracking how many packs are ordered by the user
      const dataWithOrders = status.get('packs').map(pack => pack.set('ordered', 0));
      const orderWithStatus = denormalize(order, 'code', dataWithOrders);
      const orderWithDrawDesc = orderWithStatus.map(pack =>
        pack.update('packDrawTypes', list => denormalize(list, 'code', draws))
      );
      const orderWithMaxPack =
        hasMaxPack && orderWithDrawDesc.push(generateMaxPack(orderWithDrawDesc));

      setUpgradeMap(orderUpgradeMap(orderWithStatus));
      setJwt(status.get('jwt'));
      setOrderEndpoint(status.get('epOrder'));
      setOrder(orderWithMaxPack || orderWithDrawDesc);
    }
  };

  onSetOrder = (newOrder, drawCode) => {
    const { order, setOrder, draws } = this.props;

    const drawId = draws.getIn([drawCode, 'code']);
    const result = setOrderContentsForDraw(newOrder, order, drawId);

    setOrder(result);
  };

  // TODO: upgrading logic should be moved to tickets.js
  updateQuantity = (id, amount) => {
    const { order, setOrder, draws, toggleRequireTicketModal, upgradeMap } = this.props;

    const index = order.findIndex(pack => pack.get('code') === id);
    const pack = order.get(index);
    const newOrdered = Math.max(pack.get('ordered') + amount, 0);
    const hasUpgrade = upgradeMap.find(map => map.get('packId') === id);

    let newState = order.setIn([index, 'ordered'], newOrdered);

    // if a monopack quantity is being increased, redistribute tickets
    // in the same draw.
    if (isMonoPack(pack) && hasUpgrade) {
      const drawId = pack.getIn(['packDrawTypes', 0, 'code']);
      let drawPacks = getMonoPacksByDrawId(drawId, newState).sortBy(p => -getQuantity(p));
      let totalTickets = getTotalTickets(drawPacks);

      // zero out the orders and allocate tickets
      drawPacks = drawPacks
        .map(p => p.set('ordered', 0))
        .map(p => {
          const quantity = getQuantity(p);
          const ordered = Math.floor(totalTickets / quantity);
          if (ordered > 0) {
            totalTickets %= quantity;
            return p.set('ordered', ordered);
          }
          return p;
        });

      newState = setOrderContentsForDraw(drawPacks, newState, drawId);
    }

    // TODO: have pack requirements set in data, currently setting the first draw as 'required'
    const firstDrawId = draws.getIn([0, 'code']);

    if (getTotalTicketsByDrawId(newState, firstDrawId) > 0 || amount < 0 || isMaxPack(pack)) {
      setOrder(newState);
    } else {
      toggleRequireTicketModal();
    }
  };

  render() {
    const { draws, order, isLoading } = this.props;

    if (isLoading) {
      return <Loading text="Retrieving Ticket Information..." />;
    }

    return (
      <Router>
        {draws.map(draw => {
          const drawCode = draw.get('code') - 1;
          const drawId = draws.getIn([drawCode, 'code']);
          const tickets = getPacksByDrawId(drawId, order);
          const bestDeal = this.extractBestDealInfo(tickets);
          return (
            <TicketOrder
              key={`ticketorder_${drawCode}`}
              path={`/${drawCode === 0 ? '/' : `tickets-${drawCode}`}`}
              generatedTickets={generateTicketQuantity(this.updateQuantity, tickets)}
              bestDeal={bestDeal}
              onSetOrder={this.onSetOrder}
              handleNextButton={this.handleNextButton}
              drawCode={drawCode}
            />
          );
        })}
      </Router>
    );
  }
}

TicketOrderContainer.propTypes = {
  order: PropTypes.object,
  onNext: PropTypes.func,
  statusEndpoint: PropTypes.array,
  isLoading: PropTypes.bool,
  jwt: PropTypes.string
};

export default TicketOrderContainer;
