// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Components
import ContactModal from 'marketing/components/ContactModal';

// Data
import data from 'data/data.json';

// Styling
import { link, linkWrapper, container } from './styles.module.css';

const BottomNav = ({
  rulesPdf,
  privacyPolicyPdf,
  details,
  toggleContactModalState,
  displayContactModal,
}) => {
  const { primaryColour } = data.raffle.appearance;

  return (
    <div className={container}>
      <div className={linkWrapper}>
        {rulesPdf && (
          <a
            href={rulesPdf}
            className={link}
            style={{ color: primaryColour }}
            target="__blank"
          >
            RULES
          </a>
        )}
      </div>
      <div className={linkWrapper}>
        {privacyPolicyPdf && (
          <a
            href={privacyPolicyPdf}
            className={link}
            style={{ color: primaryColour }}
            target="__blank"
          >
            PRIVACY POLICY
          </a>
        )}
      </div>
      <div className={linkWrapper}>
        <ContactModal
          details={details}
          toggleContactModalState={toggleContactModalState}
          displayContactModal={displayContactModal}
        />
      </div>
    </div>
  );
};

BottomNav.propTypes = {
  details: PropTypes.string.isRequired,
  rulesPdf: PropTypes.string.isRequired,
  privacyPolicyPdf: PropTypes.string.isRequired,
  displayContactModal: PropTypes.bool.isRequired,
  toggleContactModalState: PropTypes.func.isRequired,
};

export default BottomNav;
