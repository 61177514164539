// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect, Field } from 'formik';

// Styles
import styles from './styles.module.css';

const cx = classNames.bind(styles);

const CheckboxField = ({
  name,
  description,
  formik: { setFieldValue, values },
}) => (
  <div className={cx(styles.radioButton, styles.checkbox)}>
    <Field
      id={`checkbox-${name}`}
      type="checkbox"
      aria-labelledby={`checkbox-label-${name}`}
      name={name}
      value={values[name]}
      checked={values[name]}
      onChange={() => {
        const currentValue = values[name];
        setFieldValue(name, !currentValue);
      }}
    />
    <label htmlFor={`checkbox-${name}`} id={`checkbox-label-${name}`}>
      {description}
    </label>
  </div>
);

CheckboxField.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  // eslint-disable-next-line
  formik: PropTypes.object.isRequired,
};

export default connect(CheckboxField);
