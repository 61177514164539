// Libraries
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

// Utilities
import { hasOrderedWaitlistedPacks, getTotalPrice, isOrdered } from 'utils/tickets';

// Components
import ErrorPage from 'buyFlow/components/ErrorPage';
import PaymentSuccess from 'buyFlow/components/PaymentSuccess';

// Data
import data from 'data/data.json';

class PaymentComplete extends PureComponent {
  componentDidMount = () => {
    const { orderSuccessful, setTitle, order, verifiedOrder } = this.props;
    const {
      buyFlowData: { desc }
    } = data.raffle;

    setTitle('Payment Status');

    if (orderSuccessful && window && window.dataLayer) {
      const transactionProducts = order
        .filter(isOrdered)
        .map(pack => ({
          sku: pack.get('code'),
          name: pack.get('desc'),
          category: pack.getIn(['packDrawTypes', 0, 'desc']),
          price: pack.get('price'),
          quantity: pack.get('ordered')
        }))
        .toArray();

      window.dataLayer.push({
        event: 'purchaseComplete',
        transactionId: verifiedOrder.get('trxId'),
        transactionAffiliation: desc,
        transactionTotal: getTotalPrice(order),
        transactionProducts
      });
    }
  };

  render() {
    const { orderSuccessful, ...rest } = this.props;

    const { order, waitlistEnabled } = rest;

    return orderSuccessful ? (
      <PaymentSuccess
        {...rest}
        isWaitlisted={hasOrderedWaitlistedPacks(order) && waitlistEnabled}
      />
    ) : (
      <ErrorPage {...rest} />
    );
  }
}

PaymentComplete.propTypes = {
  orderSuccessful: PropTypes.bool.isRequired,
  setTitle: PropTypes.func.isRequired,
  order: ImmutablePropTypes.list,
  verifiedOrder: ImmutablePropTypes.list
};

PaymentComplete.defaultProps = {
  order: null,
  verifiedOrder: null
};

export default PaymentComplete;
