import axios from 'axios';
import { fromJS } from 'immutable';
import data from 'data/data.json';

// TODO: Phone number should be taken from data, eg. orderTicketsPage.tollFreePhone
/* eslint-disable max-len */

const { raffle: { orderTicketsPage: { tollFreePhone } = {} } = {} } = data;

const contactNumber = tollFreePhone || '1-888-546-7636';

export const errors = {
  STATUS: {
    title: 'Call to Order',
    message: [
      `Online ordering is temporarily unavailable. Please call ${contactNumber} to place your order. Operators are available 24/7.`,
      'We apologize for any inconvenience. Thanks for your support!'
    ]
  },
  SESSION: {
    title: 'The following error(s) occurred:',
    message: [
      `Your session has expired. Please re-enter your order or call ${contactNumber} and we will be happy to process your order 24/7 by phone.`
    ]
  },
  VERIFY: {
    title: 'The following error(s) occurred:',
    message: [
      `An error occurred while processing your transaction. Please verify your information and try again or call ${contactNumber} and we will be happy to process your order 24/7 by phone.`
    ]
  },
  PAYMENT: {
    title: 'The following error(s) occurred:',
    message: [
      `An error occurred while processing your transaction. Please verify your information and try again or call ${contactNumber} and we will be happy to process your order 24/7 by phone.`
    ]
  }
};
/* eslint-enable max-len */

export const getIp = () =>
  axios.get('https://jsonip.com/', { crossdomain: true }).then(res => res.data.ip);

export const extractAuthnetKeys = verifiedOrder => {
  const AUTHNET_ID = 0;
  const AUTHNET_KEY = 1;
  const AUTHNET_URL = 2;
  const params = index => verifiedOrder.getIn(['payments', 0, 'parms', index]);

  return verifiedOrder
    .set(
      'params',
      fromJS({
        login: params(AUTHNET_ID),
        key: params(AUTHNET_KEY)
      })
    )
    .set('url', params(AUTHNET_URL));
};

const useProxy =
  process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging'
    ? 'https://cors-anywhere.domain7.net/'
    : 'https://cors-anywhere.herokuapp.com/';

export const get = url =>
  axios
    .get(`${useProxy}${url}`, { crossdomain: true })
    .then(res => res)
    .catch(error => error.response);

export const post = (url, order) =>
  axios
    .post(`${useProxy}${url}`, order)
    .then(res => res)
    .catch(error => error.response);

export const reportPaymentStatus = () => axios.post().then(res => res);

const mockLocation = {
  ip: '97.107.183.118',
  type: 'ipv4',
  continent_code: 'NA',
  continent_name: 'North America',
  country_code: 'CA',
  country_name: 'Canada',
  region_code: 'BC',
  region_name: 'British Columbia',
  city: 'Vancouver',
  zip: 'V6B',
  latitude: 49.2788,
  longitude: -123.1139,
  location: {
    geoname_id: 6173331,
    capital: 'Ottawa',
    languages: [
      {
        code: 'en',
        name: 'English',
        native: 'English'
      },
      {
        code: 'fr',
        name: 'French',
        native: 'Fran\u00e7ais'
      }
    ],
    country_flag: 'http://assets.ipstack.com/flags/ca.svg',
    country_flag_emoji: '\ud83c\udde8\ud83c\udde6',
    country_flag_emoji_unicode: 'U+1F1E8 U+1F1E6',
    calling_code: '1',
    is_eu: false
  }
};

export async function getLocationByIp() {
  // const ip = await getIp();
  // const loc = await axios.get(`${process.env.GEO_URL}${ip}?access_key=${process.env.GEO_KEY}`)
  //  .then(res => res.data)
  const loc = mockLocation;
  return loc;
}
