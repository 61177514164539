// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Components
import MarketingAttribute from 'buyFlow/components/MarketingAttribute';

const MarketingAttributeList = ({ attributes }) => attributes.map(attribute => (
  <MarketingAttribute
    attribute={attribute}
    key={`attrib-${attribute.code}`}
  />
));

MarketingAttributeList.propTypes = {
  attributes: PropTypes.arrayOf(
    PropTypes.shape({
      list: PropTypes.array,
      dataType: PropTypes.string,
      code: PropTypes.number,
      label: PropTypes.string,
    })
  ),
};

MarketingAttributeList.defaultProps = {
  attributes: [],
}
  
  export default MarketingAttributeList;