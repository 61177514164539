// Libraries
import React from 'react';

// Components
import CheckboxField from 'buyFlow/components/CheckboxField';

// Styling
import { fieldGroup,
  fieldDetails,
  consentWrapper,
  link } from './styles.module.css';

const EmailSignup = () => (
  <div className={fieldGroup} id="consent">
    <div className="h5 theme-color-primary">Keep me informed</div>
    <p>
      Stay up-to-date with important deadlines, exciting prize details, and
      special lottery news. Plus, be the first to know if you are one of our
      lucky winners!
    </p>
    <div className={consentWrapper}>
      <CheckboxField
        name="consent"
        description="I consent to having SOA Home Lottery
        Foundation collect my name and email address."
      />
      <p style={{ paddingLeft: '40px' }}>
        I understand that I can withdraw my consent at any time.
      </p>
    </div>
    <p className={fieldDetails} style={{ margin: '25px 0' }}>
      Read our
      <a href="/privacy" className={link}>
        {' '}
        Privacy Policy
      </a>
      {' '}
      to see how we protect and manage your data.
    </p>
  </div>
);

export default EmailSignup;
