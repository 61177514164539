// Libraries
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

// Componenets
import OrderCard from 'buyFlow/components/OrderCard';
import BundleOrderCard from 'buyFlow/components/BundleOrderCard';

// Styling

const CurrentOrderList = ({ drawTotals, bundleTotals }) => (
  <div>
    {drawTotals.map(draw => (
      <OrderCard key={`d${draw.get('code')}`} draw={draw} />
    ))}
    {bundleTotals.map(pack => (
      <BundleOrderCard key={`pack${pack.get('code')}`} pack={pack} />
    ))}
  </div>
);

export default CurrentOrderList;

CurrentOrderList.propTypes = {
  drawTotals: ImmutablePropTypes.list.isRequired,
  bundleTotals: ImmutablePropTypes.list.isRequired
};
