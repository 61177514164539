// Libraries
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'formik';
import InputMask from 'react-input-mask';

// Components
import Field from 'buyFlow/components/Field';

// Styling
import styles from './styles.module.css';

const cx = classNames.bind(styles);

class PhoneField extends PureComponent {
  render() {
    const { name, formik, ...rest } = this.props;
    const { errors, touched } = formik;
    const hasError = errors[name] && touched[name];

    return (
      <>
        <Field
          name={name}
          {...rest}
          render={({ field }) => (
            <InputMask
              {...field}
              mask="(999) 999 9999"
              id={name}
              className={cx({ invalidInput: hasError })}
            />
          )}
        />
      </>
    );
  }
}

PhoneField.propTypes = {
  name: PropTypes.string,
  // eslint-disable-next-line
  formik: PropTypes.object.isRequired,
};

PhoneField.defaultProps = {
  name: '',
}

export default connect(PhoneField);
