// Libraries
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { fromJS } from 'immutable';
import { navigate } from '@reach/router';

// Utilities
import { errors } from 'utils/api';

// Data
import { raffle } from 'data/data.json';

// Components
import ErrorPage from 'buyFlow/components/ErrorPage';

class ErrorContainer extends PureComponent {
  onTryAgain = () => {
    const { setDrawStage, error, setError } = this.props;

    const resetPages = () => {
      setError('');
      setDrawStage(0);
    };

    if (error === errors.SESSION) {
      this.reinitializeBuyFlow();
      navigate('/buy-flow');
    } else {
      resetPages();
      navigate('/buy-flow/confirm-order');
    }
  };

  reinitializeBuyFlow = () => {
    const {
      setOrder,
      setPurchaserInfo,
      setJwt,
      setTransactionId,
      setError,
      setDrawStage
    } = this.props;
    const { packTypes } = raffle.buyFlowData;
    const initialOrderState = fromJS(packTypes).map(pack => pack.set('ordered', 0));

    setTransactionId('');
    setOrder(initialOrderState);
    setPurchaserInfo(undefined);
    setJwt('');
    setError('');
    setDrawStage(0);
  };

  render() {
    const { error, children, location } = this.props;

    if (error && location) {
      return <ErrorPage {...error} reinitializeBuyFlow={this.reinitializeBuyFlow} />;
    }

    return children;
  }
}

ErrorContainer.propTypes = {
  setOrder: PropTypes.func.isRequired,
  setPurchaserInfo: PropTypes.func.isRequired,
  setDrawStage: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  setJwt: PropTypes.func.isRequired,
  setTransactionId: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  error: ImmutablePropTypes.list,
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired
};

ErrorContainer.defaultProps = {
  error: null
};

export default ErrorContainer;
