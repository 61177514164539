// Libraries
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames/bind';

// Utilities
import { displayDrawQuantities } from 'utils/tickets';

// Styling
import styles from './styles.module.css';

const cx = classNames.bind(styles);

const OrderCard = ({ draw }) => {
  const totalTickets = draw.get('totalTickets');

  return (
    <div className={styles.orderCard}>
      <div className={cx(styles.cardTitle, 'theme-color-primary')}>
        {draw.get('desc')}
      </div>
      <div className={cx(styles.drawOrderBreakdown, 'theme-color-primary')}>
        <strong>
          {`${totalTickets} Ticket${totalTickets === 1 ? '' : 's'} `}
        </strong>
        <span>{`(${displayDrawQuantities(draw.get('packs'))})`}</span>
      </div>
    </div>
  );
};

OrderCard.propTypes = {
  draw: ImmutablePropTypes.map.isRequired,
};

export default OrderCard;
