// Libraries
import React, { PureComponent } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

// Utilities
import { formatPhoneNumber } from 'utils/helpers';

// Components
import Loading from 'buyFlow/components/Loading';
import WaitlistAlert from 'buyFlow/components/WaitlistAlert';

// Styling
import styles from './styles.module.css';

// Workaround to add accept.js code for payment authorization
const acceptCode = `
    function responseHandler(response) {
      if (response.messages.resultCode === "Error") {
        paymentFormError(response.messages.message)
      } else {
        paymentFormUpdate(response);
      }
    }

    function paymentFormError(message) {
      var i = 0;
      var result = [];
      while (i < message.length) {
        var msg = message[i].code + ": " + message[i].text
        console.log(msg);
        result.push(msg);
        i = i + 1;
      }

      document.getElementById("authorizeErrors").innerHTML = result.join(', ')
    }
    
    function paymentFormUpdate(response) {
      var paymentData = response.opaqueData;
      var cardData = response.encryptedCardData;
      var cardNumber = cardData.cardNumber
        .slice(cardData.cardNumber.length - 4, cardData.cardNumber.length);

      var expDate = cardData.expDate;

      document.getElementById("dataDescriptor").value = paymentData.dataDescriptor;
      document.getElementById("dataValue").value = paymentData.dataValue;
      document.getElementById("maskedPan").value = [cardData.bin, '******', cardNumber].join('');
      document.getElementById("expMm").value = expDate.slice(0,2);
      document.getElementById("expYy").value = expDate.slice(expDate.length-2,expDate.length);
      
      document.getElementById("finishButton").click();
    }
    `;

class OrderSummary extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      waitlistAccepted: false
    };

    this.dataValue = React.createRef();
    this.dataDescriptor = React.createRef();
    this.maskedPan = React.createRef();
    this.expMm = React.createRef();
    this.expYy = React.createRef();
  }

  componentWillMount() {
    const script = document.createElement('script');
    const scriptText = document.createTextNode(acceptCode);

    script.appendChild(scriptText);
    document.head.appendChild(script);
  }

  componentDidMount() {
    const { confirmOrder } = this.props;

    confirmOrder();
  }

  handleSubmit = event => {
    const { onSubmit, verifiedOrder, paymentEndpoint } = this.props;
    event.preventDefault();
    // TODO: support separate maskedPan/expMm/expYY for multiple payments post-mvp
    onSubmit({
      nonce: this.dataValue.current.value,
      descriptor: this.dataDescriptor.current.value,
      maskedPan: this.maskedPan.current.value,
      expMm: this.expMm.current.value,
      expYy: this.expYy.current.value,
      verifiedOrder,
      paymentEndpoint
    });
  };

  printNames = ticketNames =>
    ticketNames.map(name => <div>{`${name.first_name} ${name.last_name}`}</div>);

  onAcceptWaitlist = () => this.setState({ waitlistAccepted: true });

  render() {
    const {
      purchaserInfo: {
        firstName,
        lastName,
        address,
        city,
        province,
        postalCode,
        country,
        email,
        homePhone,
        mobilePhone,
        printPurchaserName,
        ticketNames
      },
      verifiedOrder,
      isLoading,
      onBack,
      isWaitlisted
    } = this.props;

    const { waitlistAccepted } = this.state;

    if (!verifiedOrder || isLoading) {
      return <Loading text="Verifying Order..." />;
    }
    if (!waitlistAccepted && isWaitlisted) {
      return <WaitlistAlert onAccept={this.onAcceptWaitlist} onBack={onBack} />;
    }

    return (
      <div className={styles.container}>
        <Helmet>
          <script type="text/javascript" src={verifiedOrder.get('url')} charset="utf-8" />
        </Helmet>
        <p className={`h4 theme-color-primary ${styles.heading}`}>Purchaser Information</p>
        <div className={styles.personalDetailsWrapper}>
          <p className={styles.personalDetails}>{`${firstName} ${lastName}`}</p>
          <p className={styles.personalDetails}>{address}</p>
          <p className={styles.personalDetails}>{`${city}, ${province}, ${postalCode}`}</p>
          <p className={styles.personalDetails}>{country}</p>
          <p>
            <span className={styles.contact}>Email</span>
            {email}
          </p>
          <p>
            <span className={styles.contact}>Home Phone</span>
            {formatPhoneNumber(homePhone)}
          </p>
          {mobilePhone && (
            <p>
              <span className={styles.contact}>Mobile Phone</span>
              {formatPhoneNumber(mobilePhone)}
            </p>
          )}
        </div>

        <p className={`h4 theme-color-primary ${styles.heading}`}>Names to Appear on Tickets</p>
        <ul className={styles.nameList}>
          {printPurchaserName && <li>{`${firstName} ${lastName}`}</li>}
          <li>{this.printNames(ticketNames)}</li>
        </ul>

        <form id="paymentForm" onSubmit={this.handleSubmit}>
          <input type="hidden" name="dataValue" id="dataValue" ref={this.dataValue} />
          <input
            type="hidden"
            name="dataDescriptor"
            id="dataDescriptor"
            ref={this.dataDescriptor}
          />
          <input type="hidden" name="maskedPan" id="maskedPan" ref={this.maskedPan} />
          <input type="hidden" name="expMm" id="expMm" ref={this.expMm} />
          <input type="hidden" name="expYy" id="expYy" ref={this.expYy} />
          <button
            type="button"
            className="AcceptUI button button--buyflow theme-background-primary"
            data-billingAddressOptions='{"show":false, "required":false}'
            data-apiLoginId={verifiedOrder.getIn(['params', 'login'])}
            data-clientKey={verifiedOrder.getIn(['params', 'key'])}
            data-acceptUIFormBtnTxt="Submit"
            data-acceptUIFormHeaderTxt="Card Information"
            data-responseHandler="responseHandler"
          >
            Pay for Order
          </button>
          <button id="finishButton" type="button" hidden onClick={this.handleSubmit} />
          <div id="authorizeErrors" className={styles.error} />
        </form>
      </div>
    );
  }
}

OrderSummary.propTypes = {
  onSubmit: PropTypes.func,
  purchaserInfo: PropTypes.object,
  confirmOrder: PropTypes.func,
  isLoading: PropTypes.bool,
  paymentEndpoint: PropTypes.string,
  verifiedOrder: PropTypes.object,
  onBack: PropTypes.func,
  isWaitlisted: PropTypes.bool
};

export default OrderSummary;
