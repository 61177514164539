// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';

// Components
import TicketButton from 'buyFlow/components/TicketButton';

// Styling
import cx from 'classnames';
import {ticketOrderContainer,
  tag,
  popular,
  ticketWrapper,
  text,
  ticketName,
  ticketPrice,
  contents as styledContents,
  controls,
  soldOut,
  contentItem,
  quantity} from './styles.module.css';

const generateContents = contents =>
  contents.map(contentsText => (
    <li className={contentItem} key={contentsText}>
      {contentsText}
    </li>
  ));

const TicketQuantity = ({ ticket, updateQuantity, contents }) => {
  const handleAdd = () => updateQuantity(ticket.get('code'), 1);
  const handleSubtract = () => updateQuantity(ticket.get('code'), -1);
  const ticketNumber = ticket.get('ordered');
  const status = ticket.get('status');
  // TODO: Do not hardcode to F&F pack
  const isBestValue = ticket.get('desc') === 'Family and Friends Pack';

  return (
    <div className={ticketOrderContainer}>
      {status === 'W' && <div className={tag}>Wait Listed</div>}
      {isBestValue && <div className={`${tag} ${popular}`}>Best Value</div>}
      <div className={ticketWrapper}>
        <div className={cx(text, 'theme-color-primary')}>
          <div className={ticketName}>{ticket.get('desc')}</div>
          {ticket.get('price') && <div className={ticketPrice}>{`$${ticket.get('price')}`}</div>}
        </div>
        {contents && contents.size > 0 && (
          <ul className={styledContents}>
            <div>Includes:</div>
            {generateContents(contents)}
          </ul>
        )}
      </div>
      {status === 'S' && <div className={soldOut}>Sold Out</div>}
      {status !== 'S' && (
        <div className={controls}>
          <TicketButton onClick={handleSubtract} icon="remove" disabled={ticketNumber === 0} />
          <div className={quantity}>{ticket.get('ordered')}</div>
          <TicketButton onClick={handleAdd} icon="add" />
        </div>
      )}
    </div>
  );
};

TicketQuantity.propTypes = {
  ticket: ImmutablePropTypes.map.isRequired,
  contents: ImmutablePropTypes.list,
  updateQuantity: PropTypes.func.isRequired
};

TicketQuantity.defaultProps = {
  contents: Immutable.List([])
};

export default TicketQuantity;
